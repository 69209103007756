<template>
	<div class="card mb-0 cursor-pointer" @click="abrir">
      <div class="card-buttons-top">
         <div class="card-body">
				<h6 class="limitador-2" >{{ scripts.nome }}</h6>
				<p class="limitador-3"><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ scripts.descricao != null ? scripts.descricao : 'Sem descrição' }}</p>
			</div>
      </div>
      <img :src="scripts.nomeArquivo == null ? '' : scripts.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Scripts',
	props: ['scripts', 'index'],
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
		})
	},
   methods: {
		abrir : function () {
         this.$emit('abrir', this.scripts)
      },
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   display: flex;
	flex-wrap: wrap;
   justify-content: center;
   align-items: end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: 0.3s;
}

.card-buttons-top:hover {
	background: rgba(0, 0, 0, 0.658);
}

h6 {
   color: #ddd;
   font-size: 20px !important;
   margin-bottom: 10px !important;
   font-weight: 700 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

p {
	color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
	line-height: 1.4;
}

img {
	aspect-ratio: 3/4;
	object-fit: cover;
}

</style>