<template>
	<div>
      <div class="row">
         <div class="col" v-if="pesquisa.resultado.length > 0">
            <div class="cards">
               <scripts v-for="(script, index) in pesquisa.resultado" :key="index" :scripts="script" :index="index" @abrir="abrirScript($event)"/>
            </div>
         </div>
         <div class="col-12 my-5 text-center" v-else>Você não tem scripts adquiridos</div>
      </div>

      <!-- modalScript -->
      <div class="modal fade" id="modalScript" aria-labelledby="modalScriptLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalScriptLabel">Script</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2 p-sm-3">
                     <div class="card-body p-sm-4">
                        <div class="row">
                           <div class="wpx-250 align-self-center mx-auto mx-md-0 mb-3 mb-md-0">
                              <img width="100%" class="rounded" :src="scripts.nomeArquivo == null ? '' : scripts.nomeArquivo" alt="banner" @error="imageError">
                           </div>
                           <div class="col-md">
                              <h4>{{ scripts.nome }}</h4>
                              <p><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ scripts.descricao != null ? scripts.descricao : 'Ver mais' }}</p>
                              <a :href="scripts.linkDocumentacao" target="_blank" v-if="scripts.linkDocumentacao != null && scripts.linkDocumentacao != ''">
                                 <h6 class="weight-400 mb-3"><i class="fas fa-file font-13 color-theme me-1"></i> Documentação</h6>
                              </a>
                              <a :href="scripts.linkDriver" target="_blank" v-if="scripts.linkDriver != null && scripts.linkDriver != ''">
                                 <h6 class="weight-400 mb-0"><i class="fab fa-google-drive font-13 color-theme me-1"></i> Drive</h6>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-12 mb-3">
                              <label><i class="fas fa-turtle font-13 color-theme me-1"></i> Link do SVN</label>
                              <input disabled type="text" class="form-control" maxlength="200" v-model="scripts.linkSvn"/>
                           </div>
                           <div class="col-6 mb-3">
                              <label><i class="fas fa-user font-13 color-theme me-1"></i> Login do SVN</label>
                              <input disabled type="text" class="form-control" maxlength="200" v-model="scripts.loginSvn"/>
                           </div>
                           <div class="col-6 mb-3">
                              <label><i class="fas fa-lock font-13 color-theme me-1"></i> Senha do SVN</label>
                              <input disabled :type="showPassword ? 'text' : 'password'" class="form-control" maxlength="200" v-model="scripts.senhaSvn"/>
                              <span @click="showPassword = !showPassword;" class="toggle-password">
                                 <i v-if="showPassword" class="fas fa-eye"></i>
                                 <i v-else class="fas fa-eye-slash"></i>
                              </span>  
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2">
                     <div class="card-body">
                        <h4 class="limitador font-18 mb-3">Video prévio</h4>
                        <div class="ratio ratio-16x9">
                           <iframe :src="scripts.link" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" 
                              allowfullscreen v-if="String(scripts.link).startsWith('https://www.youtube.com/embed/') || String(scripts.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                           </iframe>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2" v-if="scripts.listFotos.length > 0">
                     <div class="card-body">
                        <h4 class="limitador font-18 mb-3">Imagens</h4>
                        <div class="cards-2">
                           <img :src="imagem.nomeArquivo" class="w-100 rounded" v-for="(imagem, index) in scripts.listFotos" :key="index" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import scripts from '@/components/admin/meusscripts/Scripts.vue'

export default {
	name: 'MeusScripts',
	data : function() {
		return {
			pesquisa: {nome: '', resultado: []},
         scripts: {"id": null, "nome": null, "descricao": null, "link": null, "nomeArquivo": null, "valor": null, "linkDriver": null, "linkSvn": null, 
            "linkDocumentacao": null, "loginSvn": null, "senhaSvn": null, "vinculo": false, "listFotos": []},
         showPassword: false
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   components: {
      scripts
   },
	methods: {
      searchMeusProdutos : function () {
         let ref = this;
         this.pesquisa.resultado = [];
         
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/searchMeusProdutos',
            params: {
               id: this.dadosUsuario.id
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirScript : function (script) {
         this.scripts = JSON.parse(JSON.stringify(script));
         $('#modalScript').modal('show')
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
	},
	mounted() {
      this.searchMeusProdutos()
	}
}

</script>

<style scoped>

img[alt="banner"] {
	aspect-ratio: 3/4;
	object-fit: cover;
}

img {
	aspect-ratio: 16/9;
	object-fit: cover;
}

.toggle-password {
   position: absolute;
   top: 73%;
   transform: translateY(-50%);
   right: 25px;
   cursor: pointer;
}

</style>